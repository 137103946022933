import Dialog from "@mui/material/Dialog";
import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {DATE_FORMAT} from "../constants";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TableContainer from "@mui/material/TableContainer";

function DetailsDialog({state, index, readOnly, handleClose, date, row, rowHaveDetails, rowTitle, rowDescription, humanize, handleWorkLogUpdateClick, handleWorkLogDeleteClick, handleWorkLogCreateClick}) {
    return <Dialog onClose={handleClose} open={state} fullWidth maxWidth="lg">
        <DialogContent>
            {state && <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={readOnly ? 3 : 4}>
                                <Typography variant="h5">{date.format(DATE_FORMAT)}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowHaveDetails(row, date) && row.byDate[date.format(DATE_FORMAT)].details.map((detail, j) =>
                            <TableRow key={`details-row-${index}-${j}`}>
                                {rowTitle(detail)}
                                {rowDescription(detail)}
                                <TableCell sx={{minWidth: 120}}>
                                    {humanize(detail.value)}
                                </TableCell>
                                {!readOnly && <TableCell align="right" sx={{minWidth: 90}}>
                                    <IconButton size="small" color="warning"
                                                onClick={() => handleWorkLogUpdateClick(detail, row)}>
                                        <EditIcon fontSize="inherit"/>
                                    </IconButton>
                                    <IconButton size="small" color="error"
                                                onClick={() => handleWorkLogDeleteClick(detail, row)}>
                                        <DeleteIcon fontSize="inherit"/>
                                    </IconButton>
                                </TableCell>}
                            </TableRow>)}

                        {!readOnly && <TableRow>
                            <TableCell align="center"
                                       colSpan={4}>
                                <IconButton size="small" color="success"
                                            onClick={() => handleWorkLogCreateClick(row, date)}>
                                    <AddIcon fontSize="inherit"/>
                                </IconButton>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>}
        </DialogContent>
    </Dialog>
}

export default DetailsDialog;