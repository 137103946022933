import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import CloseIcon from '@mui/icons-material/Close';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment";
import {
    DATE_FORMAT,
    RESULT_GROUP_NONE, RESULT_GROUP_PROJECT, RESULT_GROUP_QUEUE,
    RESULT_GROUP_WORKER,
    TIME_FORMAT_HOURS,
    TIME_FORMAT_MINUTES
} from "../constants";
import {post} from "../requests";
import GroupsDialog from "./GroupsDialog";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function* generateMoments(start, end, step) {
    const variableMoment = start.clone();

    while (true) {
        yield variableMoment.clone();

        variableMoment.add(step);
        if (variableMoment >= end) {
            if( !variableMoment.isAfter(end) ) {
                yield variableMoment.clone();
            }

            break;
        }
    }
}

function FilterDialog({ handleClose, state, onApply, users, queues, startLoading, endLoading, showError, groups, myUserIdentity, projects, reload }) {
    const [ selectedUsers, setSelectedUsers ] = useState([]);
    const [ selectedQueues, setSelectedQueues ] = useState([]);
    const [ selectedProjects, setSelectedProjects ] = useState([]);
    const [ dateFrom, setDateFrom ] = useState(moment());
    const [ dateTo, setDateTo ] = useState(moment());
    const [ timeFormat, setTimeFormat ] = useState(TIME_FORMAT_HOURS);
    const [ resultGroup, setResultGroup ] = useState(RESULT_GROUP_NONE);

    const [ groupDialogState, setGroupsDialogState ] = useState(false);

    useEffect(() => {
        if( reload ) {
            handleApplyClick();
        }
    }, [reload]);

    const handleApplyClick = () => {
        if( resultGroup === RESULT_GROUP_PROJECT && selectedProjects.length === 0 ) {
            return showError("Выберите, хотя бы один проект");
        }

        startLoading();

        const dates = [...generateMoments(dateFrom, dateTo, moment.duration({days: 1}))];

        const fetchData = async () => {
            const response = await post("/api/v1/result", {
                userIdentities: selectedUsers.map(user => String(user)),
                queues: selectedQueues,
                dateFrom: dateFrom.format(),
                dateTo: dateTo.format(),
                group: resultGroup,
                projects: selectedProjects
            });

            return {
                workLogs: response.workLogs,
                issues: response.issues
            };
        };

        fetchData().then( ({workLogs, issues}) => onApply({ dates, workLogs, issues, selectedProjects, timeFormat, resultGroup, userIdentities: selectedUsers.map(user => String(user))}) ).catch( showError ).finally( () => endLoading() );
    };

    const handleGroupSelection = group => {
        setSelectedUsers( group.members.map(memberId => parseInt(memberId)) );
        setGroupsDialogState(false);
    };

    return <Dialog
        fullScreen
        open={state}
        onClose={handleClose}
        TransitionComponent={Transition}
    >
        <GroupsDialog state={groupDialogState} groups={groups} handleClose={() => setGroupsDialogState(false)} onSelect={group => handleGroupSelection(group)} />

        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Фильтр
                </Typography>
                <Button color="inherit" onClick={() => handleApplyClick()}>
                    Применить
                </Button>
            </Toolbar>
        </AppBar>
        <Container component="main" sx={{mt: 2, mb: 2}} maxWidth={false}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                    <Autocomplete
                        multiple
                        value={users.filter( value => selectedUsers.includes(value.value) )}
                        onChange={(event, newInputValue) => {
                            setSelectedUsers(newInputValue.map( user => user.value ));
                        }}
                        options={users}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) => value && value.value === option.value}
                        getOptionLabel={option => option.label}
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={`filter-user-${option.value}`}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Пользователи" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    {groups.length > 0 && <Button variant="outlined" size="large" fullWidth onClick={() => setGroupsDialogState(true)} sx={{mb: 1}}>
                        Выбрать группу
                    </Button>}
                    <Button variant="outlined" size="large" fullWidth onClick={() => setSelectedUsers([myUserIdentity])}>
                        Выбрать себя
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        value={queues.filter( value => selectedQueues.includes(value.value) )}
                        onChange={(event, newInputValue) => {
                            setSelectedQueues(newInputValue.map( queue => queue.value ));
                        }}
                        options={queues}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) => value && value.value === option.value}
                        getOptionLabel={option => option.label}
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={`filter-queue-${option.value}`}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Очереди" />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        value={projects.filter( value => selectedProjects.includes(value.value) )}
                        onChange={(event, newInputValue) => {
                            setSelectedProjects(newInputValue.map( queue => queue.value ));
                        }}
                        options={projects}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) => value && value.value === option.value}
                        getOptionLabel={option => option.label}
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={`filter-project-${option.value}`}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.label}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Проекты" />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <DesktopDatePicker
                            label="Дата (с)"
                            inputFormat="DD.MM.YYYY"
                            value={dateFrom}
                            onChange={(newValue) => {
                                setDateFrom(newValue);
                            }}
                            disableFuture
                            maxDate={dateTo}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <DesktopDatePicker
                            label="Дата (по)"
                            value={dateTo}
                            inputFormat="DD.MM.YYYY"
                            onChange={(newValue) => {
                                setDateTo(newValue);
                            }}
                            disableFuture
                            minDate={dateFrom}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Отображение времени</FormLabel>
                        <RadioGroup row
                                    value={timeFormat}
                                    onChange={(e, newValue) => setTimeFormat(newValue)}
                        >
                            <FormControlLabel value={TIME_FORMAT_HOURS} control={<Radio/>} label="Часы"/>
                            <FormControlLabel value={TIME_FORMAT_MINUTES} control={<Radio/>} label="Минуты"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl>
                        <FormLabel>Группировка</FormLabel>
                        <RadioGroup row
                                    value={resultGroup}
                                    onChange={(e, newValue) => setResultGroup(newValue)}
                        >
                            <FormControlLabel value={RESULT_GROUP_NONE} control={<Radio/>} label="Нет"/>
                            <FormControlLabel value={RESULT_GROUP_WORKER} control={<Radio/>} label="Автор"/>
                            <FormControlLabel value={RESULT_GROUP_QUEUE} control={<Radio/>} label="Очередь"/>
                            <FormControlLabel value={RESULT_GROUP_PROJECT} control={<Radio/>} label="Проект"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Button onClick={() => handleApplyClick()} fullWidth>Применить</Button>
                </Grid>
            </Grid>
        </Container>
    </Dialog>
}

export default FilterDialog;