export const TIME_FORMAT_HOURS = "HOURS";
export const TIME_FORMAT_MINUTES = "MINUTES";

export const RESULT_GROUP_NONE = "NONE";
export const RESULT_GROUP_WORKER = "WORKER";
export const RESULT_GROUP_QUEUE = "QUEUE"; // Группировка по очереди
export const RESULT_GROUP_PROJECT = "PROJECT";

export const DATE_FORMAT = "DD.MM.YYYY";

export const WEEKEND_WEEK_DAYS = [6, 7];

export const CREATE_WORK_LOG_FORM_TYPE_BASIC = "BASIC";
export const CREATE_WORK_LOG_FORM_TYPE_ADVANCED = "ADVANCED";

export const TASK_SEARCH_TYPE_BASE = "BASE";
export const TASK_SEARCH_TYPE_BOARD = "BOARD";

export const AUTHORIZED_STATE_DONE = "DONE";
export const AUTHORIZED_STATE_NONE = "NONE";
export const AUTHORIZED_STATE_NO_ORG_ID = "NO_ORG_ID";