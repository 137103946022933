import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import React, {useEffect, useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
    CREATE_WORK_LOG_FORM_TYPE_ADVANCED,
    CREATE_WORK_LOG_FORM_TYPE_BASIC,
    DATE_FORMAT,
    RESULT_GROUP_NONE, RESULT_GROUP_PROJECT, RESULT_GROUP_QUEUE,
    RESULT_GROUP_WORKER,
    WEEKEND_WEEK_DAYS
} from "../constants";
import {
    durationToISO, extractDuration,
    humanizeDuration,
    yandexTrackerIssueUrl,
    yandexTrackerProjectUrl,
    yandexTrackerQueueUrl
} from "../helpers";
import Table from "@mui/material/Table";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import CreateWorkLogDialog from "./CreateWorkLogDialog";
import DeleteWorkLogDialog from "./DeleteWorkLogDialog";
import UpdateWorkLogDialog from "./UpdateWorkLogDialog";
import Grid from "@mui/material/Grid";
import DetailsDialog from "./DetailsDialog";
import Alert from "@mui/material/Alert";
import RestrictionsDialog from "./RestrictionsDialog";
import ChartsDialog from "./ChartsDialog";

const daySx = day => {
    if (WEEKEND_WEEK_DAYS.includes(day.isoWeekday())) {
        return {
            minWidth: 130,
            background: "#F9F7F2"
        };
    }

    return {
        minWidth: 130,
    };
};

const rowSx = (row, index) => {
    if( row.titleRow === true ) {
        return {
            background: 'rgba(0, 0, 255, 0.12)'
        }
    }

    if( (index+1) %2 === 0 ) {
        return {
            background: 'rgba(0, 0, 0, 0.04)'
        };
    }

    return {};
};

const rowTitle = row => {
    if( row.url === "" || !row.url ) {
        return <TableCell>
            {row.title}
            {row.externalDescription && <div>{row.externalDescription}</div>}
        </TableCell>
    }

    return <TableCell>
        <Link href={row.url} target="_blank">{row.title}</Link>
        {row.externalDescription && <div>{row.externalDescription}</div>}
    </TableCell>
};

const rowDescription = row => {
    if( row.description !== "" ) {
        return <TableCell>{row.description}</TableCell>
    }

    return <TableCell />
};

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0,0,0,0)',
        maxWidth: 750,
    },
}));

function ResultTable({workLogs, admin, dates, timeFormat, myUserIdentity, users, showError, startLoading, endLoading, readOnly, showSuccess, resultGroup, boards, userIdentities, setWorkLogs, queues, selectedProjects, issues, projects}) {
    const [ rows, setRows ] = useState([]);
    const [ restrictionsDialog, setRestrictionsDialog ] = useState(false);
    const [ chartsDialog, setChartsDialog ] = useState( false );

    const humanize = duration => humanizeDuration(duration, timeFormat);
    const isLastRow = index => rows.length - 1 === index;
    const rowDateExists = (row, date) => !!row.byDate[date.format(DATE_FORMAT)];
    const rowHaveDetails = (row, date) => rowDateExists(row, date) && row.byDate[date.format(DATE_FORMAT)].details.length > 0;

    const [detailsDialog, setDetailsDialog] = useState({
        open: false,
        data: null,
        row: null,
        index: 0
    });

    const [ createWorkLogData, setCreateWorkLogData ] = useState({
        open: false,
        userIdentity: null,
        projectId: "",
        issueKey: "",
        duration: "",
        comment: "",
        date: moment(),
        form: CREATE_WORK_LOG_FORM_TYPE_BASIC,
        issueTitle: ""
    });

    const [ deleteWorkLogData, setDeleteWorkLogData ] = useState({
        open: false,
        issueKey: "",
        workLogId: 0,
        title: ""
    });

    const [ updateWorkLogData, setUpdateWorkLogData ] = useState({
        open: false,
        issueKey: "",
        workLogId: 0,
        title: "",
        duration: "",
        comment: ""
    });

    const handleWorkLogUpdateClick = (detail, row) => {
        setUpdateWorkLogData( prev => ({
            ...prev,
            open: true,
            workLogId: detail.workLogId,
            issueKey: detail.issueKey,
            title: row.title + ", " + detail.title + ": " + humanize(detail.value),
            duration: durationToISO(detail.value),
            comment: detail.description
        }))
    };

    const handleWorkLogDeleteClick = (detail, row) => {
        setDeleteWorkLogData( prev => ({
            ...prev,
            open: true,
            workLogId: detail.workLogId,
            issueKey: detail.issueKey,
            title: row.title + ", " + detail.title + ": " + humanize(detail.value)
        }))
    };

    const handleWorkLogCreateClick = (row, date) => {
        setCreateWorkLogData( prev => ({
            ...prev,
            open: true,
            duration: "",
            comment: "",
            projectId: row.projectId,
            userIdentity: resultGroup === RESULT_GROUP_NONE || resultGroup === RESULT_GROUP_QUEUE || resultGroup === RESULT_GROUP_PROJECT || !admin ? myUserIdentity : parseInt(row.createdBy),
            issueKey: row.issueKey,
            date: date,
            form: resultGroup === RESULT_GROUP_NONE || resultGroup === RESULT_GROUP_QUEUE || resultGroup === RESULT_GROUP_PROJECT ? CREATE_WORK_LOG_FORM_TYPE_BASIC : CREATE_WORK_LOG_FORM_TYPE_ADVANCED,
            issueTitle: row.title,
        }))
    };

    const handleWorkLogCreateClose = () => {
        setCreateWorkLogData( prev => ({...prev, open: false}));
    };

    const handleWorkLogDeleteClose = () => {
        setDeleteWorkLogData( prev => ({...prev, open: false}));
    };

    const handleWorkLogUpdateClose = () => {
        setUpdateWorkLogData( prev => ({...prev, open: false}));
    };

    const handleCreateWorkLogSubmit = workLog => {
        if( createWorkLogData.projectId !== "" ) {
            workLog.projectId = createWorkLogData.projectId;
        }

        if( userIdentities.length > 0 && !userIdentities.includes(workLog.createdById) ) {
            showSuccess("Рабочее время добавлено, но из-за настроек фильтра не может быть показано.");
        } else {
            showSuccess("Рабочее время добавлено");
            setWorkLogs(prev => [...prev, workLog]);
        }

        handleWorkLogCreateClose();
    };

    const handleDeleteWorkLogSubmit = ({workLogId}) => {
        setWorkLogs(prev => prev.filter( log => {
            return log.workLogId !== workLogId;
        }));

        handleWorkLogDeleteClose();
    };

    const handleUpdateWorkLogSubmit = workLog => {
        setWorkLogs(prev => prev.map(log => {
            if( log.workLogId === workLog.workLogId ) {
                workLog.projectId = log.projectId;

                return workLog;
            }

            return log;
        }));

        handleWorkLogUpdateClose();
    };

    const prepareRows = () => {
        const out = [];
        const mapping = {};

        const totalRow = {
            title: "Итого",
            byDate: {},
            value: 0
        };

        const existsQueues = [];
        const existsProjects = [];

        for( const workLog of workLogs ) {
            const dateKey = moment(workLog.createdAt).format(DATE_FORMAT);

            if( !totalRow.byDate[dateKey] ) {
                totalRow.byDate[dateKey] = {
                    value: 0,
                    details: []
                };
            }

            totalRow.byDate[dateKey].value += workLog.duration;
            totalRow.value += workLog.duration;

            switch(resultGroup) {
                case RESULT_GROUP_WORKER:
                    if( !mapping[workLog.createdById] ) {
                        mapping[workLog.createdById] = {
                            title: workLog.createByDisplay,
                            createdBy: workLog.createdById,
                            byDate: {},
                            value: 0,
                            url: "",
                            issueKey: ""
                        };
                    }

                    if( !mapping[workLog.createdById].byDate[dateKey] ) {
                        mapping[workLog.createdById].byDate[dateKey] = {
                            value: 0,
                            details: []
                        };
                    }

                    mapping[workLog.createdById].value += workLog.duration;
                    mapping[workLog.createdById].byDate[dateKey].value += workLog.duration;
                    mapping[workLog.createdById].byDate[dateKey].details.push({
                        workLogId: workLog.workLogId,
                        issueKey: workLog.issueKey,
                        createdBy: workLog.createdBy,
                        title: `${workLog.issueKey}: ${workLog.issueDisplay}`,
                        url: yandexTrackerIssueUrl(workLog.issueKey),
                        description: workLog.comment,
                        value: workLog.duration
                    });

                    break;
                case RESULT_GROUP_NONE:
                case RESULT_GROUP_QUEUE:
                case RESULT_GROUP_PROJECT:
                    if( resultGroup === RESULT_GROUP_QUEUE ) {
                        const queue = queues.find( q => q.value === workLog.queue );
                        const queueTitle = queue ? queue.label : workLog.queue;

                        if( !existsQueues.includes(workLog.queue) ) {
                            existsQueues.push(workLog.queue);
                        }

                        if( !mapping[workLog.queue] ) {
                            mapping[workLog.queue] = {
                                title: queueTitle,
                                createdBy: "",
                                byDate: {},
                                value: 0,
                                url: yandexTrackerQueueUrl(workLog.queue),
                                issueKey: "",
                                titleRow: true,
                            };
                        }

                        if( !mapping[workLog.queue].byDate[dateKey] ) {
                            mapping[workLog.queue].byDate[dateKey] = {
                                value: 0,
                                details: []
                            };
                        }

                        mapping[workLog.queue].value += workLog.duration;
                        mapping[workLog.queue].byDate[dateKey].value += workLog.duration;
                        mapping[workLog.queue].byDate[dateKey].details.push({
                            workLogId: workLog.workLogId,
                            issueKey: workLog.issueKey,
                            createdBy: workLog.createdBy,
                            title: `${workLog.issueKey}: ${workLog.issueDisplay}`,
                            externalDescription: workLog.createByDisplay,
                            url: yandexTrackerIssueUrl(workLog.issueKey),
                            description: workLog.comment,
                            value: workLog.duration
                        });
                    }

                    if( resultGroup === RESULT_GROUP_PROJECT ) {
                        const project = projects.find( p => p.value === workLog.projectId );
                        const projectTitle = project ? project.label : "Проект #" + workLog.projectId;

                        if( !existsProjects.includes(workLog.projectId) ) {
                            existsProjects.push(workLog.projectId);
                        }

                        const projectKey = `-PROJECT-${workLog.projectId}`;

                        if( !mapping[projectKey] ) {
                            mapping[projectKey] = {
                                title: projectTitle,
                                createdBy: "",
                                byDate: {},
                                value: 0,
                                url: yandexTrackerProjectUrl(workLog.projectId),
                                issueKey: "",
                                titleRow: true,
                            };
                        }

                        if( !mapping[projectKey].byDate[dateKey] ) {
                            mapping[projectKey].byDate[dateKey] = {
                                value: 0,
                                details: []
                            };
                        }

                        mapping[projectKey].value += workLog.duration;
                        mapping[projectKey].byDate[dateKey].value += workLog.duration;
                        mapping[projectKey].byDate[dateKey].details.push({
                            workLogId: workLog.workLogId,
                            issueKey: workLog.issueKey,
                            createdBy: workLog.createdBy,
                            title: `${workLog.issueKey}: ${workLog.issueDisplay}`,
                            externalDescription: workLog.createByDisplay,
                            url: yandexTrackerIssueUrl(workLog.issueKey),
                            description: workLog.comment,
                            value: workLog.duration
                        });
                    }

                    if( !mapping[workLog.issueKey] ) {
                        mapping[workLog.issueKey] = {
                            title: (resultGroup === RESULT_GROUP_QUEUE ? ' | ' : '') + `${workLog.issueKey}: ${workLog.issueDisplay}`,
                            issueKey: workLog.issueKey,
                            byDate: {},
                            value: 0,
                            projectId: workLog.projectId,
                            url: yandexTrackerIssueUrl(workLog.issueKey),
                            createdBy: "",
                        };
                    }

                    if( !mapping[workLog.issueKey].byDate[dateKey] ) {
                        mapping[workLog.issueKey].byDate[dateKey] = {
                            value: 0,
                            details: []
                        };
                    }

                    mapping[workLog.issueKey].value += workLog.duration;
                    mapping[workLog.issueKey].byDate[dateKey].value += workLog.duration;
                    mapping[workLog.issueKey].byDate[dateKey].details.push({
                        workLogId: workLog.workLogId,
                        issueKey: workLog.issueKey,
                        createdBy: workLog.createdBy,
                        title: workLog.createByDisplay,
                        url: "",
                        description: workLog.comment,
                        value: workLog.duration
                    });


                    break;
            }
        }

        if( resultGroup === RESULT_GROUP_PROJECT && issues.length > 0 ) {
            for( const projectId of selectedProjects ) {
                if( !mapping[`-PROJECT-${projectId}`] ) {
                    const project = projects.find( p => p.value === projectId );
                    const projectTitle = project ? project.label : "Проект #" + projectId;

                    if( !existsProjects.includes(projectId) ) {
                        existsProjects.push(projectId);
                    }

                    mapping[`-PROJECT-${projectId}`] = {
                        title: projectTitle,
                        createdBy: "",
                        byDate: {},
                        value: 0,
                        url: yandexTrackerProjectUrl(projectId),
                        issueKey: "",
                        titleRow: true,
                    };
                }

                for( const issue of issues ) {
                    if (!mapping[issue.key]) {
                        mapping[issue.key] = {
                            title: `${issue.key}: ${issue.summary}`,
                            issueKey: issue.key,
                            projectId: projectId,
                            byDate: {},
                            value: 0,
                            url: yandexTrackerIssueUrl(issue.key),
                            createdBy: "",
                        };
                    }
                }
            }
        }

        if( existsQueues.length > 0 ) {
            // Если есть очереди, надо сгруппировать
            for( const queue of existsQueues ) {
                out.push(mapping[queue]);

                for( const key of Object.keys(mapping) ) {
                    if( key === queue ) {
                        continue;
                    }

                    if( key.indexOf(queue) === 0 ) {
                        out.push(mapping[key]);
                    }
                }
            }
        } else if( existsProjects.length > 0 ) {
            for( const projectId of existsProjects ) {
                out.push(mapping[`-PROJECT-${projectId}`]);

                for( const row of Object.values(mapping) ) {
                    if( row.projectId === projectId ) {
                        out.push(row);
                    }
                }
            }
        } else {
            for( const row of Object.values(mapping) ) {
                out.push(row);
            }
        }

        if( resultGroup === RESULT_GROUP_WORKER && (users.length !== out.length - 1 || userIdentities.length === 0 ) ) {
            // Добавляем строки по всем пользователям.
            const haveUserIdentitiesFilter = userIdentities.length > 0;

            for( const user of users ) {
                const userIdentity = String(user.value);

                if( mapping[userIdentity] || (haveUserIdentitiesFilter && !userIdentities.includes(userIdentity) ) ) {
                    continue;
                }

                out.push({
                    title: user.label,
                    byDate: {},
                    createdBy: userIdentity,
                    url: "",
                    value: 0
                });
            }
        }

        if( resultGroup === RESULT_GROUP_NONE && selectedProjects.length > 0 && issues.length > 0 ) {
            for( const issue of issues ) {
                if( !mapping[issue.key] ) {
                    out.push({
                        title: (resultGroup === RESULT_GROUP_QUEUE ? ' | ' : '') + `${issue.key}: ${issue.summary}`,
                        issueKey: issue.key,
                        byDate: {},
                        value: 0,
                        url: yandexTrackerIssueUrl(issue.key),
                        createdBy: "",
                    });
                }
            }
        }

        out.push(totalRow);

        setRows(out);
    };

    useEffect(() => {
        prepareRows();
    }, [workLogs, resultGroup]);

    let relativeIndex = 0;

    const renderedRows = rows.map((row, index) => {
        relativeIndex++;

        if( row.titleRow === true ) {
            relativeIndex = -1;
        }

        return <TableRow sx={rowSx(row, relativeIndex)} key={`table-row-${index}`}>
            {rowTitle(row)}

            {dates.map(date => <TableCell align="center" sx={daySx(date)}
                                          key={`table-cell-${index}-${date}-${row.title}`}>
                {!isLastRow(index) && row.titleRow !== true && <HtmlTooltip
                    title={
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 700}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={readOnly ? 3 : 4}>
                                                <Typography variant="h5">{date.format(DATE_FORMAT)}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowHaveDetails(row, date) && row.byDate[date.format(DATE_FORMAT)].details.map((detail, j) =>
                                            <TableRow key={`details-row-${index}-${j}`}>
                                                {rowTitle(detail)}
                                                {rowDescription(detail)}
                                                <TableCell sx={{minWidth: 120}}>
                                                    {humanize(detail.value)}
                                                </TableCell>
                                                {!readOnly && <TableCell align="right" sx={{minWidth: 90}}>
                                                    <IconButton size="small" color="warning"
                                                                onClick={() => handleWorkLogUpdateClick(detail, row)}>
                                                        <EditIcon fontSize="inherit"/>
                                                    </IconButton>
                                                    <IconButton size="small" color="error"
                                                                onClick={() => handleWorkLogDeleteClick(detail, row)}>
                                                        <DeleteIcon fontSize="inherit"/>
                                                    </IconButton>
                                                </TableCell>}
                                            </TableRow>)}

                                        {!readOnly && <TableRow>
                                            <TableCell align="center"
                                                       colSpan={4}>
                                                <IconButton size="small" color="success"
                                                            onClick={() => handleWorkLogCreateClick(row, date)}>
                                                    <AddIcon fontSize="inherit"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    }
                >
                    <Button onClick={() => setDetailsDialog(prev => ({...prev, open: true, row, date, index}))}>
                        {rowDateExists(row, date) && row.byDate[date.format(DATE_FORMAT)].value > 0 ? humanize(row.byDate[date.format(DATE_FORMAT)].value) : "---"}
                    </Button>
                </HtmlTooltip>}
                {(isLastRow(index) || row.titleRow === true) && <Button disabled={true} sx={{color: "black !important"}}>
                    {rowDateExists(row, date) && row.byDate[date.format(DATE_FORMAT)].value > 0 ? humanize(row.byDate[date.format(DATE_FORMAT)].value) : "---"}
                </Button>}
            </TableCell>)}

            <TableCell align="right">
                {humanize(row.value)}
            </TableCell>
        </TableRow>
    });

    const showCharts = () => {
        setChartsDialog(true);
    };

    const exportData = () => {
        const header = [
            ""
        ];

        for( const date of dates ) {
            header.push(date.format(DATE_FORMAT));
        }

        header.push("Итого");

        const csv = [
            header.join(";")
        ];

        for( const row of rows ) {
            const parts = [
                row.title,
            ];

            for( const date of dates ) {
                const { rawMinutes } = extractDuration(rowDateExists(row, date) ? row.byDate[date.format(DATE_FORMAT)].value : 0)

                parts.push(rawMinutes);
            }

            const { rawMinutes } = extractDuration(row.value);

            parts.push( rawMinutes );

            csv.push(parts.join(";"))
        }

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv.join("\n"));
        hiddenElement.target = '_blank';

        hiddenElement.download = 'export-time-sheet.csv';
        hiddenElement.click();
    };

    return <React.Fragment>
        <ChartsDialog state={chartsDialog} workLogs={workLogs} resultGroup={resultGroup} handleClose={() => setChartsDialog(false)} timeFormat={timeFormat} dates={dates} />

        <RestrictionsDialog state={restrictionsDialog} handleClose={() => setRestrictionsDialog(false)} readOnly={readOnly} admin={admin} />

        <CreateWorkLogDialog admin={admin} state={createWorkLogData.open} boards={boards} handleClose={handleWorkLogCreateClose} data={createWorkLogData} setData={setCreateWorkLogData} users={users} showError={showError} showSuccess={showSuccess} myUserIdentity={myUserIdentity} startLoading={startLoading} endLoading={endLoading} onSubmit={handleCreateWorkLogSubmit} resultGroup={resultGroup} />

        <DeleteWorkLogDialog state={deleteWorkLogData.open} issueKey={deleteWorkLogData.issueKey} workLogId={deleteWorkLogData.workLogId} handleCLose={handleWorkLogDeleteClose} title={deleteWorkLogData.title} showError={showError} showSuccess={showSuccess} startLoading={startLoading} endLoading={endLoading} onSubmit={handleDeleteWorkLogSubmit} />

        <UpdateWorkLogDialog state={updateWorkLogData.open} issueKey={updateWorkLogData.issueKey} workLogId={updateWorkLogData.workLogId} handleClose={handleWorkLogUpdateClose} title={updateWorkLogData.title} showError={showError} showSuccess={showSuccess} startLoading={startLoading} endLoading={endLoading} onSubmit={handleUpdateWorkLogSubmit} data={updateWorkLogData} />

        <DetailsDialog
            state={detailsDialog.open}
            handleClose={() => setDetailsDialog( prev => ({...prev, open: false}))}
            date={detailsDialog.date}
            row={detailsDialog.row}
            rowHaveDetails={rowHaveDetails}
            rowTitle={rowTitle}
            rowDescription={rowDescription}
            humanize={humanize}
            handleWorkLogUpdateClick={handleWorkLogUpdateClick}
            handleWorkLogDeleteClick={handleWorkLogDeleteClick}
            handleWorkLogCreateClick={handleWorkLogCreateClick}
            index={detailsDialog.index}
            readOnly={readOnly}
        />

        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                {(readOnly || !admin) && <span>
                    На вашу учётную запись действуют ограничения. <Button onClick={() => setRestrictionsDialog(true)}>Подробнее</Button>
                </span>}
            </Grid>

            <Grid item xs={12} md={2}>
                <Button fullWidth color="info" variant="outlined" onClick={() => showCharts()}>Графики</Button>
            </Grid>

            <Grid item xs={12} md={2}>
                <Button fullWidth color="success" variant="outlined" onClick={() => exportData()}>Экспорт</Button>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{minWidth: 300}}/>
                                {dates.map(date => <TableCell align="center" sx={daySx(date)}
                                                              key={`table-head-${date.format()}`}>
                                    {date.format(DATE_FORMAT)}
                                </TableCell>)}
                                <TableCell align="right" sx={{minWidth: 120}}>Итого</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderedRows}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    </React.Fragment>
}

export default ResultTable;