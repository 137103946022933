import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import Backdrop from "@mui/material/Backdrop";

function Loader({ state }) {
    return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1500 }}
        open={state}
    >
        <CircularProgress color="success" />
    </Backdrop>
}

export default Loader;