import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {DATE_FORMAT} from "../constants";
import {humanizeDuration} from "../helpers";
import Typography from "@mui/material/Typography";
import moment from "moment";

import { Chart as ChartJS, registerables } from 'chart.js';

import { Pie, Bar } from 'react-chartjs-2';

ChartJS.register(...registerables);

function ChartsDialog({state, handleClose, workLogs, resultGroup, timeFormat, dates}) {
    const [ charts, setCharts ] = useState([]);

    const durationValueFormatter = value => {
        if( parseInt(value) === value ) {
            return humanizeDuration(value, timeFormat);
        }

        if( parseInt(value.parsed) > 0 ) {
            return humanizeDuration(value.parsed, timeFormat);
        }

        if( parseInt(value.raw) > 0 ) {
            return humanizeDuration(value.raw, timeFormat);
        }

        return humanizeDuration(0, timeFormat);
    };

    const prepareCharts = () => {
        const out = [];

        const pieChartByUsers = {};
        const pieChartByIssues = {};
        const pieChartByDate = {};
        const pieChartByQueue = {};

        const barChartByUsersAndDate = {}; // USERID => DATE
        const barChartByQueueAndDate = {}; // QUEUE => DATE
        const barChartByIssueAndDate = {};

        const users = {};

        for (const log of workLogs) {
            const date = moment(log.createdAt).format(DATE_FORMAT);

            if( !users[log.createdById] ) {
                users[log.createdById] = log.createByDisplay;
            }

            if (!pieChartByUsers[log.createdById]) {
                pieChartByUsers[log.createdById] = {id: log.createdById, label: log.createByDisplay, value: 0}
            }

            if( !barChartByUsersAndDate[log.createdById] ) {
                barChartByUsersAndDate[log.createdById] = {
                    label: log.createByDisplay,
                    data: {}
                };

                for( const originalDate of dates ) {
                    barChartByUsersAndDate[log.createdById].data[originalDate.format(DATE_FORMAT)] = 0;
                }
            }

            if( !barChartByQueueAndDate[log.queue] ) {
                barChartByQueueAndDate[log.queue] = {
                    label: log.queue,
                    data: {}
                };

                for( const originalDate of dates ) {
                    barChartByQueueAndDate[log.queue].data[originalDate.format(DATE_FORMAT)] = 0;
                }
            }

            if( !barChartByIssueAndDate[log.issueKey] ) {
                barChartByIssueAndDate[log.issueKey] = {
                    label: log.issueKey + ": " + log.issueDisplay,
                    data: {}
                };

                for( const originalDate of dates ) {
                    barChartByIssueAndDate[log.issueKey].data[originalDate.format(DATE_FORMAT)] = 0;
                }
            }

            if (!pieChartByIssues[log.issueKey]) {
                pieChartByIssues[log.issueKey] = {
                    id: log.issueKey,
                    label: log.issueKey + ": " + log.issueDisplay,
                    value: 0
                }
            }

            if (!pieChartByDate[date]) {
                pieChartByDate[date] = {id: date, label: date, value: 0}
            }

            if (!pieChartByQueue[log.queue]) {
                pieChartByQueue[log.queue] = {id: log.queue, label: log.queue, value: 0}
            }

            pieChartByUsers[log.createdById].value += log.duration;
            pieChartByIssues[log.issueKey].value += log.duration;
            pieChartByDate[date].value += log.duration;
            pieChartByQueue[log.queue].value += log.duration;

            barChartByUsersAndDate[log.createdById].data[date] += log.duration;
            barChartByQueueAndDate[log.queue].data[date] += log.duration;
            barChartByIssueAndDate[log.issueKey].data[date] += log.duration;

        }

        if( pieChartByUsers.length > 1 ) {
            out.push({
                type: "PIE",
                label: "Итого (Сотрудники)",
                md: 6,
                data: {
                    labels: Object.values(pieChartByUsers).map( v => v.label ),
                    datasets: [
                        {
                            data: Object.values(pieChartByUsers),
                        },
                    ]
                },

                options: {
                    plugins: {
                        legend: {
                            position: "right",
                        },
                        tooltip: {
                            callbacks: {
                                label: durationValueFormatter
                            }
                        }
                    }
                },
            });
        }

        out.push({
            type: "PIE",
            label: "Итого (Задачи)",
            md: 6,
            data: {
                labels: Object.values(pieChartByIssues).map( v => v.label ),
                datasets: [
                    {
                        data: Object.values(pieChartByIssues),
                    },
                ]
            },

            options: {
                plugins: {
                    legend: {
                        position: "right",
                    },
                    tooltip: {
                        callbacks: {
                            label: durationValueFormatter
                        }
                    }
                }
            },
        });

        out.push({
            type: "PIE",
            label: "Итого (Очереди)",
            md: 6,
            data: {
                labels: Object.values(pieChartByQueue).map( v => v.label ),
                datasets: [
                    {
                        data: Object.values(pieChartByQueue),
                    },
                ]
            },

            options: {
                plugins: {
                    legend: {
                        position: "right",
                    },
                    tooltip: {
                        callbacks: {
                            label: durationValueFormatter
                        }
                    }
                }
            },
        });

        out.push({
            type: "BAR_STACKED",
            label: "По дням (Сотрудники)",

            md: 12,

            data: {
                labels: dates.map( d => d.format(DATE_FORMAT)),
                datasets: Object.keys(barChartByUsersAndDate).map( key => {
                    return {
                        label: users[key],
                        data: Object.values(barChartByUsersAndDate[key].data),
                        stack: "stack-o"
                    }
                })
            },

            options: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,

                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return durationValueFormatter(value);
                            }
                        }
                    }
                },

                plugins: {
                    tooltip: {
                        callbacks: {
                            label: value => {
                                return value.dataset.label + ": " + durationValueFormatter(value);
                            }
                        }
                    }
                }
            }
        });

        out.push({
            type: "BAR_STACKED",
            label: "По дням (Очереди)",

            md: 12,

            data: {
                labels: dates.map( d => d.format(DATE_FORMAT)),
                datasets: Object.keys(barChartByQueueAndDate).map( key => {
                    return {
                        label: barChartByQueueAndDate[key].label,
                        data: Object.values(barChartByQueueAndDate[key].data),
                        stack: "stack-o"
                    }
                })
            },

            options: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,

                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return durationValueFormatter(value);
                            }
                        }
                    }
                },

                plugins: {
                    tooltip: {
                        callbacks: {
                            label: value => {
                                return value.dataset.label + ": " + durationValueFormatter(value);
                            }
                        }
                    }
                }
            }
        });

        out.push({
            type: "BAR_STACKED",
            label: "По дням (Задачи)",

            md: 12,

            data: {
                labels: dates.map( d => d.format(DATE_FORMAT)),
                datasets: Object.keys(barChartByIssueAndDate).map( key => {
                    return {
                        label: barChartByIssueAndDate[key].label,
                        data: Object.values(barChartByIssueAndDate[key].data),
                        stack: "stack-o"
                    }
                })
            },

            options: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,

                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return durationValueFormatter(value);
                            }
                        }
                    }
                },

                plugins: {
                    tooltip: {
                        callbacks: {
                            label: value => {
                                return value.dataset.label + ": " + durationValueFormatter(value);
                            }
                        }
                    }
                }
            }
        });

        setCharts(out);
    };

    useEffect(() => {
        prepareCharts();
    }, [workLogs, resultGroup]);

    return <Dialog open={state} onClose={() => handleClose()} fullWidth maxWidth="lg">
        <DialogTitle>Графики</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{paddingTop: 1}}>
                    {charts.map( chart => <Grid item xs={12} md={chart.md} style={{ height: 500 + `px`, marginBottom: 20 + `px`}}>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                            {chart.label}
                        </Typography>

                        {chart.type === "PIE" && <Pie
                            data={chart.data}
                            options={chart.options}
                        />}

                        {chart.type === "BAR_STACKED" && <Bar
                            data={chart.data}
                            options={chart.options}
                        />}
                    </Grid>)}
            </Grid>
        </DialogContent>

        <DialogActions>
            <Button onClick={() => handleClose()} color="warning">Закрыть</Button>
        </DialogActions>
    </Dialog>
}

export default ChartsDialog;